import * as React from 'react'

import Components from './Components'
import { StoryblokBase } from '../../@types/storyblok'

interface StoryblokEntryProps {
    pageContext: {
        story: StoryblokBase,
        lang: string
    };
    location: Location;
}

class StoryblokEntry extends React.Component<StoryblokEntryProps, { story: StoryblokBase }> {
    static getDerivedStateFromProps(props: any, state: any) {
        if (state.story.uuid === props.pageContext.story.uuid) {
            return null
        }

        return StoryblokEntry.prepareStory(props)
    }

    static prepareStory(props: StoryblokEntryProps) {
        const story = Object.assign({}, props.pageContext.story)
        story.content = JSON.parse(story.content)

        return { story }
    }

    constructor(props: StoryblokEntryProps) {
        super(props)
        this.state = StoryblokEntry.prepareStory(props)
    }

    render() {
        let content = this.state.story.content
        return (
            <div>
                {React.createElement(Components(content.component), {
                    key: content._uid,
                    blok: content,
                    location: this.props.location,
                    lang: this.props.pageContext.lang
                })}
            </div>
        )
    }
}

export default StoryblokEntry
